import "./prices.css";
import React, { useState } from "react";
import Fuse from "fuse.js";
import { allExamsSortedByName } from "../Examinations/exams/allExamsFinal";
import ExamCTAList from "../Examinations/ExamCTA/ExamCTAList";

const Prices = () => {
    /* --------- FUZZY SEARCHING --------- */
    const [searchQuery, setSearchQuery] = useState(""); //
    const [searchResults, setSearchResults] = useState([]);
    const [showResults, setShowResults] = useState(false);
  /* --------- PAGINATION --------- */
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const filterExamsByPage = () => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return allExamsSortedByName.slice(startIndex, endIndex);
  };

   /* --------- FUZZY SEARCHING --------- */
   const fuseOptions = {
    keys: ["name", "typeOfExamination"], // Define which keys to search in
    includeScore: true, // Include a score to rank results by similarity
  };

  const fuse = new Fuse(allExamsSortedByName, fuseOptions);

  const handleSearch = () => {
    setSearchResults([]); // Clear previous results
    if (searchQuery) {
      setShowResults(true); // Show results when the search button is clicked
      const searchResults = fuse.search(searchQuery);
      const formattedResults = searchResults.map((result) => result.item);
      setSearchResults(formattedResults);
    } else {
      setShowResults(false); // Hide results if the search query is empty
    }
  };

  const handleInputChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    // Reset showResults if the query becomes empty
    if (!query) {
      setShowResults(false);
    }
  };


  return (
    <>
      <div className="container navbar-margin">
        <h1 className="heading-primary">Árak</h1>
        <div className="exam-company-cta-box">
          <p>
            <strong>
              Kérjen időpontot, esetleg merült fel kérdése a vizsgálati
              eredményével kapcsolatban keressen minket bizalommal e-mail-en
              vagy telefonon!
            </strong>
          </p>
          <ExamCTAList />
        </div>
             {/* KERESÉS */}
             <h3 className="heading-secondary examinations-secondary-heading">
          Keresés vizsgálatok között
        </h3>
        <div className="examination-search-container">
          <input
            type="text"
            id="search"
            placeholder="Keresés vizsgálatok között..."
            value={searchQuery}
            onChange={handleInputChange}
            className="examination-search-input"
          />

          {/* Search button */}
          <button
            onClick={handleSearch}
            className="btn btn-lg examination-search-button"
          >
            Keresés
          </button>
        </div>
        {/* Display search results if showResults is true */}
        {showResults && (
          <div className="examination-search-container">
            <div className="row">
              {searchResults.map((exam) => (
                <div className="col-md-6" key={exam.id}>
                  <div className="exam-card mb-3">
                    <div className="card-body-row">
                      <h5 className="exam-card-title">{exam.name}</h5>
                      <p className="exam-card-price-text">{exam.price}</p>
                      <p className="exam-card-issue-text">
                        {exam.resultIssueDay}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {/* TELJES VIZSGÁLATI LISTA */}
        <h3 className="heading-secondary">
          Teljes laborvizsgálati lista, árakkal{" "}
        </h3>
        {/* Pagination controls -- TOP */}
        <nav aria-label="Page navigation" className="exams-page-navigation">
          <div
            className="btn-group"
            role="group"
            aria-label="Basic outlined example"
          >
            <button
              type="button"
              className={`page-item ${
                currentPage === 1 ? "disabled" : ""
              } btn btn-outline-primary pagination-prev-button`}
              onClick={handlePrevPage}
            >
              Előző
            </button>
            <button
              type="button"
              className={`page-item ${
                currentPage ===
                Math.ceil(allExamsSortedByName.length / pageSize)
                  ? "disabled"
                  : ""
              } btn btn-outline pagination-next-button`}
              onClick={handleNextPage}
            >
              Következő
            </button>
          </div>
        </nav>
        {/* Összes vizsgálat */}
        <div className="exams">
          {/* Extra row with titles */}
          <div className="examination-table-title-container">
            <div className="exam-card mb-3">
              <div className="card-body-row examination-table-top">
                <h5 className="exam-card-title table-top-text">
                  Vizsgálat neve
                </h5>
                <p className="exam-card-price-text table-top-text">
                  Vizsgálat ára
                </p>
                <p className="exam-card-issue-text table-top-text">
                  Vizsgálat kiadási idő
                </p>
              </div>
            </div>
          </div>
          {/* Display exams for the current page */}
          <div className="container">
            <div className="row">
              {filterExamsByPage().map((exam) => (
                <div className="col-md-6" key={exam.id}>
                  <div className="exam-card mb-3">
                    <div className="card-body-row">
                      <h5 className="exam-card-title">{exam.name}</h5>
                      <p className="exam-card-price-text">{exam.price}</p>
                      <p className="exam-card-issue-text">
                        {exam.resultIssueDay}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* Pagination controls -- BOTTOM */}
            <nav aria-label="Page navigation" className="exams-page-navigation">
              <div
                className="btn-group"
                role="group"
                aria-label="Basic outlined example"
              >
                <button
                  type="button"
                  className={`page-item ${
                    currentPage === 1 ? "disabled" : ""
                  } btn btn-outline-primary pagination-prev-button`}
                  onClick={handlePrevPage}
                >
                  Előző
                </button>
                <button
                  type="button"
                  className={`page-item ${
                    currentPage ===
                    Math.ceil(allExamsSortedByName.length / pageSize)
                      ? "disabled"
                      : ""
                  } btn btn-outline pagination-next-button`}
                  onClick={handleNextPage}
                >
                  Következő
                </button>
              </div>
            </nav>
          </div>
        </div>
        <p className="prices-description-text">
          Vizsgálataink árait a következő PDF dokumentumokban találja:
        </p>
        <div className="prices-container">
          <div className="prices-wrapper">
            <p className="prices-file-text">Teljes vizsgálati lista árakkal</p>
            <a
              href="/assets/medical/prices/balintanalitika_orvosdiagnosztika_arlista_20240704.pdf"
              target="_blank"
              rel="noreferrer"
              className="btn btn-lg prices-download-button"
            >
              Megnézem
            </a>
          </div>
          <div className="prices-wrapper">
            <p className="prices-file-text">
              Vizsgálati csomagjaink magánszemélyek részére
            </p>
            <a
              href="/assets/medical/prices/balintanalitika_orvosdiagnosztika_csomagok_arlista_20240320.pdf"
              target="_blank"
              rel="noreferrer"
              className="btn btn-lg prices-download-button"
            >
              Megnézem
            </a>
          </div>
          <div className="prices-wrapper">
            <p className="prices-file-text">
              Vizsgálati csomagjaink cégek részére
            </p>
            <a
              href="/assets/medical/prices/balintanalitika_orvosdiagnosztika_ceges-csomagok_lista_2024.pdf"
              target="_blank"
              rel="noreferrer"
              className="btn btn-lg prices-download-button"
            >
              Megnézem
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Prices;
