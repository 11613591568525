export const examinationEgyebVizsgalatok = [
  {
    id: 1,
    name: "Anti -CCP (Filaggrin)",
    oldPrice: "5300 Ft",
    newPrice: "5800 Ft",
    resultIssueDay: "21-24 nap",
    typeOfExamination: "egyéb vizsgálatok",
  },
  {
    id: 2,
    name: "Réz (Cu)",
    oldPrice: "7600 Ft",
    newPrice: "8500 Ft",
    resultIssueDay: "21-24 nap",
    typeOfExamination: "egyéb vizsgálatok",
  },
  {
    id: 3,
    name: "Litium",
    oldPrice: "2500 Ft",
    newPrice: "3000 Ft",
    resultIssueDay: "21-24 nap",
    typeOfExamination: "egyéb vizsgálatok",
  },
  {
    id: 4,
    name: "Adiponektin",
    oldPrice: "11900 Ft",
    newPrice: "12900 Ft",
    resultIssueDay: "21-24 nap",
    typeOfExamination: "egyéb vizsgálatok",
  },
  {
    id: 5,
    name: "Coeliakia panel (tTG-IgA/tTG-IgG)",
    oldPrice: "9000 Ft",
    newPrice: "9500 Ft",
    resultIssueDay: "21-24 nap",
    typeOfExamination: "egyéb vizsgálatok",
  },
  {
    id: 6,
    name: "EMA-IgA/EMA-IgG",
    oldPrice: "15000 Ft",
    newPrice: "15500 Ft",
    resultIssueDay: "21-24 nap",
    typeOfExamination: "egyéb vizsgálatok",
  },
  {
    id: 7,
    name: "Revers T3",
    oldPrice: "6700 Ft",
    newPrice: "7500 Ft",
    resultIssueDay: "21-24 nap",
    typeOfExamination: "egyéb vizsgálatok",
  },
  {
    id: 8,
    name: "Inhibin -B",
    oldPrice: "15500 Ft",
    newPrice: "15500 Ft",
    resultIssueDay: "21-24 nap",
    typeOfExamination: "egyéb vizsgálatok",
  },
  {
    id: 9,
    name: "Alfa-1 antitripszin",
    oldPrice: "3500 Ft",
    newPrice: " 4000 Ft",
    resultIssueDay: "21-24 nap",
    typeOfExamination: "egyéb vizsgálatok",
  },
  {
    id: 10,
    name: "Renin",
    oldPrice: "7750 Ft",
    newPrice: "8800 Ft",
    resultIssueDay: "21-24 nap",
    typeOfExamination: "egyéb vizsgálatok",
  },
  {
    id: 11,
    name: "Androsztendion",
    oldPrice: "5500 Ft",
    newPrice: "6000 Ft",
    resultIssueDay: "21-24 nap",
    typeOfExamination: "egyéb vizsgálatok",
  },
  {
    id: 12,
    name: "Parathormon (PTH)",
    oldPrice: "5300 Ft",
    newPrice: "6200 Ft",
    resultIssueDay: "21-24 nap",
    typeOfExamination: "egyéb vizsgálatok",
  },
  {
    id: 13,
    name: "Aldoszteron (ALD)",
    oldPrice: "6100 Ft",
    newPrice: "7300 Ft",
    resultIssueDay: "21-24 nap",
    typeOfExamination: "egyéb vizsgálatok",
  },
  {
    id: 14,
    name: "GH (Növekedési hormon)",
    oldPrice: "4200 Ft",
    newPrice: "5200 Ft",
    resultIssueDay: "21-24 nap",
    typeOfExamination: "egyéb vizsgálatok",
  },
  {
    id: 15,
    name: "ACTH",
    oldPrice: "6000 Ft",
    newPrice: "6900 Ft",
    resultIssueDay: "21-24 nap",
    typeOfExamination: "egyéb vizsgálatok",
  },
  {
    id: 16,
    name: "Fruktózamin",
    oldPrice: "2100 Ft",
    newPrice: "2600 Ft",
    resultIssueDay: "21-24 nap",
    typeOfExamination: "egyéb vizsgálatok",
  },
  {
    id: 17,
    name: "ENA szűrés",
    oldPrice: "11200 Ft",
    newPrice: "11200 Ft",
    resultIssueDay: "21-24 nap",
    typeOfExamination: "egyéb vizsgálatok",
  },
  {
    id: 18,
    name: "ANA szűrés (ELISA)",
    oldPrice: "7500 Ft",
    newPrice: "8000 Ft",
    resultIssueDay: "21-24 nap",
    typeOfExamination: "egyéb vizsgálatok",
  },
  {
    id: 19,
    name: "Homocisztein",
    oldPrice: "9000 Ft",
    newPrice: "9500 Ft",
    resultIssueDay: "21-24 nap",
    typeOfExamination: "egyéb vizsgálatok",
  },
  {
    id: 20,
    name: "Chromoganin A",
    oldPrice: "15000 Ft",
    newPrice: "15000 Ft",
    resultIssueDay: "21-24 nap",
    typeOfExamination: "egyéb vizsgálatok",
  },
  {
    id: 21,
    name: "CA-72-4",
    oldPrice: "3700 Ft",
    newPrice: "4800 Ft",
    resultIssueDay: "21-24 nap",
    typeOfExamination: "egyéb vizsgálatok",
  },
  {
    id: 22,
    name: "IGF-1 (Inzulinszerű növekedési faktor)",
    oldPrice: "5650 Ft",
    newPrice: "6500 Ft",
    resultIssueDay: "21-24 nap",
    typeOfExamination: "egyéb vizsgálatok",
  },
  {
    id: 23,
    name: "Legionella IgG/IgM",
    oldPrice: "9000 Ft",
    newPrice: "9500 Ft",
    resultIssueDay: "21-24 nap",
    typeOfExamination: "egyéb vizsgálatok",
  },
  {
    id: 24,
    name: "Anti HBsA titer",
    oldPrice: "4500 Ft",
    newPrice: "5000 Ft",
    resultIssueDay: "21-24 nap",
    typeOfExamination: "egyéb vizsgálatok",
  },
  {
    id: 25,
    name: "Hisztamintolerancia (DAO)",
    oldPrice: "14000 Ft",
    newPrice: "14500 Ft",
    resultIssueDay: "21-24 nap",
    typeOfExamination: "egyéb vizsgálatok",
  },
  {
    id: 26,
    name: "Neurospecifikus enoláz (NSE)",
    oldPrice: "6000 Ft",
    newPrice: "6500 Ft",
    resultIssueDay: "21-24 nap",
    typeOfExamination: "egyéb vizsgálatok",
  },
  {
    id: 27,
    name: "C-peptid",
    oldPrice: "5000 Ft",
    newPrice: "5500 Ft",
    resultIssueDay: "21-24 nap",
    typeOfExamination: "egyéb vizsgálatok",
  },
  {
    id: 28,
    name: "Cifra-21",
    oldPrice: "8000 Ft",
    newPrice: "8500 Ft",
    resultIssueDay: "21-24 nap",
    typeOfExamination: "egyéb vizsgálatok",
  },
  {
    id: 29,
    name: "S-100",
    oldPrice: "6500 Ft",
    newPrice: "7500 Ft",
    resultIssueDay: "21-24 nap",
    typeOfExamination: "egyéb vizsgálatok",
  },
  {
    id: 30,
    name: "Coeruloplazmin",
    oldPrice: "3400 Ft",
    newPrice: "4000 Ft",
    resultIssueDay: "21-24 nap",
    typeOfExamination: "egyéb vizsgálatok",
  },
  {
    id: 31,
    name: "17-OH Progeszteron",
    oldPrice: "3500 Ft",
    newPrice: "4000 Ft",
    resultIssueDay: "21-24 nap",
    typeOfExamination: "egyéb vizsgálatok",
  },
  {
    id: 32,
    name: "Calcitonin",
    oldPrice: "4500 Ft",
    newPrice: "5000 Ft",
    resultIssueDay: "21-24 nap",
    typeOfExamination: "egyéb vizsgálatok",
  },
  {
    id: 33,
    name: "Deamidált gliadin peptid IgA",
    oldPrice: "9500 Ft",
    newPrice: "10000 Ft",
    resultIssueDay: "21-24 nap",
    typeOfExamination: "egyéb vizsgálatok",
  },
  {
    id: 34,
    name: "Deamidált gliadin peptid IgG",
    oldPrice: "9500 Ft",
    newPrice: "10000 Ft",
    resultIssueDay: "21-24 nap",
    typeOfExamination: "egyéb vizsgálatok",
  },
  {
    id: 35,
    name: "Hepatitis A IgM/IgG",
    oldPrice: "5700 Ft",
    newPrice: "6200 Ft",
    resultIssueDay: "21-24 nap",
    typeOfExamination: "egyéb vizsgálatok",
  },
  {
    id: 36,
    name: "Vitamin A",
    oldPrice: "13000 Ft",
    newPrice: "13500 Ft",
    resultIssueDay: "21-24 nap",
    typeOfExamination: "egyéb vizsgálatok",
  },
  {
    id: 37,
    name: "Zn meghatározás",
    oldPrice: "7600 Ft",
    newPrice: "8800 Ft",
    resultIssueDay: "21-24 nap",
    typeOfExamination: "egyéb vizsgálatok",
  },
  {
    id: 38,
    name: "Epesav",
    oldPrice: "10500 Ft",
    newPrice: "11000 Ft",
    resultIssueDay: "21-24 nap",
    typeOfExamination: "egyéb vizsgálatok",
  },
  {
    id: 39,
    name: "TPA",
    oldPrice: "5000 Ft",
    newPrice: "5500 Ft",
    resultIssueDay: "21-24 nap",
    typeOfExamination: "egyéb vizsgálatok",
  },
  {
    id: 40,
    name: "COV2S - Elleni antitest kimutatás",
    oldPrice: "7000 Ft",
    newPrice: "7500 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "egyéb vizsgálatok",
  },
  {
    id: 41,
    name: "Szelén meghatározás teljes vérből",
    oldPrice: "6800 Ft",
    newPrice: "10000 Ft",
    newPriceBy240320: "8000 Ft",
    resultIssueDay: "10-15 nap",
    typeOfExamination: "egyéb vizsgálatok",
  },
  {
    id: 42,
    name: "Jód meghatározása 24 órás gyűjtött vizeletből",
    oldPrice: "5800 Ft",
    newPrice: "10000 Ft",
    newPriceBy240320: "8000 Ft",
    resultIssueDay: "10-15 nap",
    typeOfExamination: "egyéb vizsgálatok",
  },
];
