export const examinationAllergologia = [
  {
    id: 1,
    name: "Allergia (légzőszervi 17 db)",
    oldPrice: "13500 Ft",
    newPrice: "14500 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "allergológia",
  },
  {
    id: 2,
    name: "Allergia (tápcsatorna 17 db)",
    oldPrice: "13500 Ft",
    newPrice: "14500 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "allergológia",
  },
  {
    id: 3,
    name: "Gyermek panel",
    oldPrice: "13500 Ft",
    newPrice: "14500 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "allergológia",
  },
  {
    id: 4,
    name: "Totál IgE",
    oldPrice: "3500 Ft",
    newPrice: "4500 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "allergológia",
  },
  {
    id: 5,
    name: "Spec. IgE",
    oldPrice: "3500 Ft",
    newPrice: "4500 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "allergológia",
  },
];
