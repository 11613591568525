import logo from "../../../assets/company_logo/balint_analitka_main_logo_white.png";
import "./Navigationbar.css";
// import { FaGlobeEurope } from "react-icons/fa";

const NavigationBar = () => {
  return (
    <nav className="navbar fixed-top  navbar-expand-xl  navbar-container nav-underline">
      {/* ----------- MENU BAR AND LOGO ----------- */}
      <div
        className="container-fluid "
        style={{ backgroundColor: "transparent" }}
      >
        <a
          className="navbar-brand"
          href="/orvosdiagnosztika"
          style={{ color: "white" }}
        >
          <img
            src={logo}
            alt="Logo"
            width="45"
            height="50"
            className="d-inline-block align-text-top navbar-logo"
          />
          <div className="navbar-logo-text">Bálint Analitika </div>
        </a>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
          color="white"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
      </div>
      {/* ----------- SOLUTIONS - PAGE SELECTION ----------- */}
      <div
        className="collapse navbar-collapse navbar-container mt-auto"
        id="navbarNavDropdown"
      >
        <ul className="navbar-nav ">
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="/orvosdiagnosztika"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Orvosdiagnosztika
            </a>
            <ul className="dropdown-menu">
              <li>
                <a className="dropdown-item" href="/kemiaianalitika">
                  Kémiai analitika
                </a>
              </li>
            </ul>
          </li>

          {/* ----------- NAV ITEM - ABOUT US ----------- */}
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="/"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Rólunk
            </a>
            <ul className="dropdown-menu col-md-6">
              <li>
                <a
                  className="dropdown-item"
                  href="/orvosdiagnosztika/rolunk/tortenetunk"
                >
                  Történetünk
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="/orvosdiagnosztika/rolunk/csapatunk"
                >
                  Csapatunk
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="/orvosdiagnosztika/rolunk/partnereink"
                >
                  Partnereink
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="/orvosdiagnosztika/rolunk/karrier"
                >
                  Karrier
                </a>
              </li>
            </ul>
          </li>

          {/* ----------- NAV ITEMS ----------- */}
          <li className="nav-item">
            <a className="nav-link" href="/orvosdiagnosztika/konzultacio">
              Konzultáció
            </a>
          </li>

          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="/vizsgalatok"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Vizsgálatok
            </a>
            <ul className="dropdown-menu">
              <li>
                <a
                  className="dropdown-item"
                  href="/orvosdiagnosztika/vizsgalatok"
                >
                  Lakossági
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="/orvosdiagnosztika/vizsgalatok/cegeknek"
                >
                  Cégeknek
                </a>
              </li>
              {/* <li>
                <a className="dropdown-item" href="/orvosdiagnosztika/araink">
                  Áraink
                </a>
              </li> */}
            </ul>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/orvosdiagnosztika/araink">
              Árak
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/orvosdiagnosztika/idopontfoglalas">
              Időpontfoglalás
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/orvosdiagnosztika/kapcsolat">
              Kapcsolat
            </a>
          </li>
          {/* ----------- LANGUAGE - LAUNGUAGE SELECTION -----------
   This section is currently commented out as language selection is not needed at the moment.
   It can be enabled in the future when multiple languages are supported.
*/}
          {/* <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="/medical"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <FaGlobeEurope />
            </a>
            <ul className="dropdown-menu col-md-1">
              <li>
                <a className="dropdown-item" href="/">
                  HU
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/">
                  ENG
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/">
                  RO
                </a>
              </li>
            </ul>
          </li> */}
        </ul>
      </div>
    </nav>
  );
};

export default NavigationBar;
