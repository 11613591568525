import ExamCTA from "../../Examinations/ExamCTA/ExamCTA";
import HeroBanner from "../../Hero/HeroBanner";
import phoneImg from "../../../../assets/medical/appointment/phone-call.png";
import landlinePhoneImg from "../../../../assets/medical/appointment/telephone.png";
import "./newsPage.css";

const NewsPage = ({ pageTitle, content, date, heroImage }) => {
  return (
    <>
      <HeroBanner srcBig={heroImage} srcSmall={heroImage} />
      <div className="container news-page-container">
        <h2 className="heading-secondary--left news-page-title">{pageTitle}</h2>
        <p className="exam-category-page-date">{date}</p>
        <div
          className="exam-category-page-content"
          dangerouslySetInnerHTML={{ __html: content }}
        />
        {pageTitle === "Otthoni vérvételi szolgáltatás" ? (
          <div className="exam-company-cta-box">
            <li className="exam-company-contact-item">
              <span className="exam-company-contact-item-icon">
                <span className="exam-company-contact-item-icon-inner">
                  <img src={phoneImg} alt="mobiltelefon" />
                </span>
              </span>

              <a
                href="tel:06202542591"
                className="exam-company-contact-item-text"
              >
                06 20 254 2591
              </a>
            </li>
            <li className="exam-company-contact-item">
              <span className="exam-company-contact-item-icon">
                <span className="exam-company-contact-item-icon-inner">
                  <img src={landlinePhoneImg} alt="vezetékes telefon" />
                </span>
              </span>

              <a
                href="tel:0612053044"
                className="exam-company-contact-item-text"
              >
                06 1 205 3044
              </a>
            </li>
          </div>
        ) : (
          <ExamCTA />
        )}
      </div>
    </>
  );
};

export default NewsPage;
