export const chemicalNewsItems = [
  {
    id: 1,
    title: "Megújultunk, elköltöztünk!",
    contentLink: "/kemiaianalitika/hirek/megujultunk-elkoltoztunk",
    picLink: "/assets/medical/home/news/news/megujultunk_news_card.png",
    heroImg: "/assets/medical/home/news/news/megujultunk_banner_nagy.png",
    date: "2023.10.10.",
    content: `A Bálint Analitika Kémiai Laboratóriuma új telephelyen (1116 Budapest, Kondorfa utca 6.) folytatja tevékenységét.`,
  },
  {
    id: 2,
    title: "Parkolás a Kémiai laborunk előtt a belső udvarban",
    contentLink: "/kemiaianalitika/hirek/parkolas-a-labor-elott",
    picLink: "/assets/medical/home/news/news/parkolas_news_card.png",
    heroImg: "/assets/medical/home/news/news/parkolas_banner_nagy.png",
    date: "2023.10.10.",
    content: `Amennyiben a laboratóriumunkhoz autóval érkezne, kérjük hogy ne a Google Térkép alapján próbáljon bejönni, mert az sajnos még nem pontos, ennek javítása még folyamatban van. Az itt található térkép alapján könnyen megtalálható a mintavételi helyszín. Köszönjük a megértésüket!
    <img src=/assets/chemical/contactus/kemiai_analitika_map.png alt="Parkolás a kémiai labor előtt a belső udvarban" />`,
  },
  {
    id: 3,
    title: `A Bálint Analitika Kft. adott otthont a 2023. évi "Mentes és funkcionális termékek" versenyének.`,
    contentLink:
      "/kemiaianalitika/hirek/mentes-es-funkcionalis-termek-versenye",
    picLink: "/assets/chemical/home/news/dijatado_card.png",
    heroImg: "/assets/chemical/home/news/dijatado_banner_nagy.png",
    date: "2023.12.05.",
    content: `Megtiszteltetés számunkra, hogy 2023 tavaszán a PPH Media munkatársai felkértek bennünket a Minőségi mentes és funkcionális termékek versenyének lebonyolításában való részvételre. A felkérésnek örömmel tettünk eleget, így a 2023. október 13-án a versenyre benevezett, több mint 150 termék érzékszervi bírálatát a közelmúltban felújított laboratóriumi épületünk hangulatos éttermében végeztük a PPH Media által felkért zsűritagok közreműködésével. A zsűriben a Bálint Analitika Kft-t Dr. Márta Zoltán ügyvezető igazgató és zsűri-elnökként Dr. Szigeti Tamás János stratégiai igazgató képviselte.
    <br /><br />
    Reméljük, hogy az élelmiszergyártás területén az illetékes szakemberek továbbra is kellő figyelmet fordítanak a különleges táplálkozási igénnyel, allergiával, étel-intoleranciával élő fogyasztók igényeire, ami sajnos gyakran életbiztonsági szükséglet is. A Bálint Analitika független, akkreditált laboratóriumi szolgáltatásaival, munkatársainak szakértelmével e célnak a megvalósítása érdekében a jövőben is készséggel vesz részt ilyen, és hasonló feladatok megoldásában.
    <br /><br />
    Végezetül, örömmel gratulálunk a 2023-as <strong>Mentes verseny</strong> díjazottjainak. Valamennyi részvevőnek pedig további innovatív, sikeres munkát, az év közeledtével meghitt karácsonyt és boldog Újévet kívánunk.
    <br /><br />
    A verseny díjátadó ünnepségéről készült összefoglaló és a rendezvényen készült képgaléria következő URL-címen tekinthető meg:
    <br /><br />
    <a href="https://storeinsider.hu/cikk/mentes-es-funkcionalis-termekek-versenye-2023-ime-a-nyertesek" target="_blank">https://storeinsider.hu/cikk/mentes-es-funkcionalis-termekek-versenye-2023-ime-a-nyertesek</a> 
    <br /><br />
     
    <strong>Dr. Márta Zoltán</strong> <br />
    Ügyvezető igazgató<br /><br />
    és<br /><br />
    <strong>Dr. Szigeti Tamás János</strong><br />
    Stratégiai igazgató`,
  },
  {
    id: 4,
    title: "Danube Sediment Balance projekt részvétel",
    contentLink: "/kemiaianalitika/hirek/danube_sediment_balance_projekt",
    picLink: "/assets/chemical/home/news/danube_sediment_card.webp",
    heroImg: "/assets/chemical/home/news/danube_sediment_banner_nagy.webp",
    date: "2024.07.15.",
    content: `
    Örömmel jelentjük be, hogy részt veszünk a Danube Sediment Balance projektben (azonosító: DanubeSediment_Q2 DRP0200029), amely a Duna vízgyűjtőjén javítja a hordalék mennyiségét és minőségét.
    Nagy megtiszteltetés számunkra, hogy részesei lehetünk ennek a jelentős kezdeményezésnek.
    <br /><br />
    A projekt célja egy integrált hordalékkezelési terv kidolgozása, amelyet a Nemzetközi Duna Védelmi Bizottság is figyelembe vesz majd.
    <br />
    A fejlesztéshez új monitoring hálózatot és értékelési módszereket alkalmazunk, az érdekelt felek bevonásával.
    <br /><br />
    A projektben való részvételünktől bővebben <a href="https://balintanalitika.hu/kemiaianalitika/rolunk/projektek">itt</a> olvashatnak.<br />
    További információkért, legfrissebb pedig a projekt saját oldalán, <a href="https://interreg-danube.eu/projects/danubesediment-q2" target="_blank" rel="noreferrer">ezen a linken</a> olvashatnak angol nyelven.
    `,
  },
];
